import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base"
import { bold20, bold24, bold36, bold52, regular12 } from "./typography"
import { StyledTab, StyledTabSelected, StyledTabsList } from "./tabs"



const Article = styled.article`
  ${baseGridStyles}
  ${props => props.background && props.background};
  @media screen and (min-width: ${breakpoints.l}px) {
    ${props => props.background && 'max-width: 100%'};
    ${props => props.type === 'faq' && 'padding: 9rem 0'};
    ${props => props.servicesList && 'padding: 0 0 10rem 0'};
  }
  
`

const ArticleHeader = styled.header`
  grid-column: 1 / 5;
  padding: 0;
  position: relative;
  ${props => props.type === 'faq' && ''};
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 1 / 7;
    margin: 0 0 0.8rem;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 3 / 11;
    margin: 0 0 1.6rem;
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column: 2 / 12;
  }

  time {
    line-height: 1.6rem;
    border-top: 1px solid var(--grey-lighter);
    border-bottom: 1px solid var(--grey-lighter);
    padding: 0.8rem 0;
    @media screen and (min-width: ${breakpoints.md}px) {
      padding: 1.6rem 0;
    }
  }
  div {
    position: absolute;
    bottom: 0.5rem;
    right: 0.8rem;
    span {
      @media screen and (min-width: ${breakpoints.md}px) {
        position: relative;
        top: 0;
        left: 0;
      }
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      bottom: 1.4rem;
    }
    a:hover,
    a:focus {
      border-bottom: none;
    }
  }
 
  p {
    margin-top: 1.6rem;
    text-align: center;
    max-width: 40rem;
    margin: 1.6rem auto 0;
    ${props => props.color && props.color};
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-top: 2.4rem;
      max-width: 80rem;
    }

  }
  
`

const ArticleH1 = styled.h1`
  text-align: ${props => props.align && props.align};
  margin-bottom: 0;
  ${props => props.color && props.color};
  ${bold24}
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    ${bold36}
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    ${bold52}
    margin-top: 3rem;
  }
`

const ArticleDate = styled.time`
  margin: 0;
  color: var(--grey-mid-dark);
  visibility: ${props => props.offScreen ? 'hidden' : 'visible'};
  display: ${props => props.offScreen ? 'none' : 'block'};
  ${regular12};
  span.publishedon {
    display: none;
    @media screen and (min-width: ${breakpoints.md}px) {
      display: inline-block;
    }
  }
`

const ArticleAuthorInfo = styled.address`
  margin: 1.6rem 0 0;
  color: var(--grey-mid-dark);
  ${regular12};
  text-indent: -5000px;
    visibility: hidden;
    width: 0;
    display: none;
  span {
    
  }
  a {
    margin-left: 3.4rem;
    position: relative;
    border-bottom: 0;
  }
`

const ArticleSection = styled.section`
  grid-column: 1 / 5;
  width: 100%;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0;
    padding: 0;
    grid-column: ${props => props.isList ? '1 / 6' : '1 / 7'};
    
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: ${props => props.isList ? '3 / 11' : props.isServicesList ? '1 / 13'  : '3 / 11'};
  
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column: ${props => props.isList ? '2 / 12' : props.isServicesList ? '1 / 13'  : '2 / 12'};
  }

  a {
    &:visited {
      
    }

    &:hover,
    &:active,
    &:focus {
      
    }
  }
  h1 {
    margin-top: 0;
  }
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    margin: 1.6rem 0 0 0;
    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 2.4rem 0 0 0;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
      margin: 3.2rem 0 0 0;
    }
  }

  h2 + h3, 
  h3 + h4, 
  h4 + h5, 
  h5 + h6 {
    margin-top: 0.8rem;
  }

  h2 + h3, 
  h3 + h4, 
  h4 + h5, 
  h5 + h6 {
    margin-top: 0.8rem;
    margin-bottom: 0;
  }

  address {
    font-style: italic;
  }

  * + p,
  * + pre,
  * + ul,
  * + ol,
  * + dl,
  * + dt,
  * + blockquote,
  * + address {
    margin-top: 1.6rem;
  }

  * + ol li,
  * + ul li {
    margin-bottom:0.8rem;
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-bottom:1.6rem;
    }
  }

  ol {
    &.no-numbers {
      list-style-type: none;
      padding-left: 0;
    }
    
    ol {
      li {
        margin-left: 1.6rem;
      }
    }

    li ul {
      margin-left: 1.6rem;
      li {
        list-style-type: circle;
        padding-left: 2rem;
      }
    }
  }



  ul.process-ticks {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 2.4rem;    
    @media screen and (min-width: ${breakpoints.sm}px) {
      columns: 2;
    }

    li {
      display: flex;
      align-items: flex-start;
      p {
        margin-top: 0;
      }
      svg {
        margin-right: 1.6rem;
        min-width: 2.4rem;
        
        @media screen and (min-width: ${breakpoints.md}px) {
          margin-top: 0.4rem;
        }
      }
    }
  }

  ul.process-ticks.single-column {
    @media screen and (min-width: ${breakpoints.sm}px) {
      columns: 1;
    }
  }
  blockquote {
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-left: 0;
      width: auto;
    }

  }

  .article-inline-image, 
  .gatsby-image-wrapper {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    margin-left: -1.6rem;
    margin-right: -1.6rem;

    &.has-caption {
      margin-bottom: 0rem;
    }

    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-left: -3.2rem;
      margin-right: -3.2rem;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
      margin-left: -8.2rem;
      margin-right: -3.2rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
      margin-left: -8rem;
      margin-right: -8rem;
    }

    &.noMargin {
      margin: 0;
    }
  }

  .alignleft {
    .gatsby-image-wrapper {
      margin: 0;
    }
  }

  

  address {
    font-style: italic;
  }
  figcaption {
  }
  figure:first-of-type{
      margin-left: -1.6rem;
      width: 100vw;
      min-width: 32rem;
      @media screen and (min-width: ${breakpoints.sm}px) {
        margin-left: 0;
        width: auto;
      }

      figcaption {
        margin: 0.6rem 1.6rem 0;
      }
  }
  .calendly-inline-widget {
    margin-left: -1.6rem;

    @media screen and (min-width: ${breakpoints.sm}px) {
      width: 700px;
    }
  }
  .social-links {
    margin-top: 1.6rem;
    line-height: 1.6rem;
    border-top: 1px solid var(--grey-lighter);
    border-bottom: 1px solid var(--grey-lighter);
    padding: 0.8rem 0;
    justify-content: space-between;
    @media screen and (min-width: ${breakpoints.md}px) {
      padding: 1.6rem 0;
      margin-top: 2.4rem;
    }
    span {
      position: relative;
      left: 0;
      top: 0;
    }
    ul {
      margin-top: 0;
    }
    a,
    a:hover,
    a:active {
      border-bottom: 0;
    }
  }

  .react-tabs__tab-list {
    ${StyledTabsList}
  }
  .react-tabs__tab {
    ${StyledTab}
    margin-left:-0.8rem;
    font-weight: 700;
  }

  .react-tabs__tab--selected {
    ${StyledTabSelected}
  }

  .react-tabs__tab-panel {
    ul.testimonials {
      list-style-type: none;
      padding-left: 0;
    }
  }

  code {
    background: var(--grey-lighter);
    padding: 1.6rem;
    display: block;
    margin-top: 1.6rem;
    p{
    font-family: monospace,monospace;
  }
  }

`

const ArticleTagsList = styled.ul`
  margin-top: 1.6rem;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding-left: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    padding-left: 0;
  }
  li {
    margin-right: 0.8rem;
    display: inline-flex;
  }

  a {
    text-decoration: none;
    ${regular12};
    border: 1px solid var(--grey-mid);
    padding: 0.4rem 0.8rem;
    border-bottom: 1px solid var(--grey-mid);

    &:hover,
    &:active,
    &:focus {
      border: 1px solid var(--light-green-dark);
      border-bottom: 1px solid var(--grey-mid);
    }
  }
`

const ArticleAside = styled.aside`
  ${baseGridStyles}

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 9;
    grid-column-end: 13;
    align-content: baseline;
  }
  h2, p, ul {
    grid-column: span 4;
    text-align: left;
    margin-top: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-top: 2.4rem;
      grid-column: span 6;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 0 0 1.6rem;
      grid-column: span 12;
      
    }
  }
  h2 {
    ${bold20}
  }
  ul {
    padding: 0;
  }
`


export { Article, ArticleHeader, ArticleSection, ArticleTagsList, ArticleDate, ArticleAuthorInfo, ArticleAside, ArticleH1 }