import { css } from "styled-components"
const StyledTabsList = css`
    display: flex;
    overflow-x: auto;
    margin: 0;
    padding: 0;
`

const StyledTab = css`
    flex-shrink: 0;
    list-style-type: none;
    padding: 1.6rem;
    margin: 0 0.4rem 0 -1.6rem;
    color: var(--white);
    cursor: pointer;
    font-weight: 700;
    &:hover {
        border-bottom: 2px solid var(--grey-light);
    }
    abbr {
        text-decoration: none;
    }
`

const StyledTabSelected = css`
    border-bottom: 2px solid var(--grey-light);


`


export {
    StyledTabsList,
    StyledTab,
    StyledTabSelected
}